<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Address</h1>
      </div>
    </div>
    <div class="card theme-card">
      <div class="card-body">
        <form @submit="saveAddress" validate>
          <div class="row">
            <div class="col">
              <label>Search By Street Address or City</label>
              <div class="input-group input-group-merge mb-3">
                <input
                  type="text"
                  class="form-control form-control-appended"
                  v-model="searchAddress"
                  @input="geocodeAddress"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span
                      v-if="searching"
                      class="fa fa-spin fa-circle-notch"
                    ></span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div
                  class="custom-control custom-radio"
                  v-for="address in results"
                  :key="address.place_id"
                >
                  <input
                    type="radio"
                    id="customRadio1"
                    v-model="addressRecord"
                    :required="true"
                    v-bind:value="address"
                    name="customRadio"
                    @change="selectAddress"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadio1">{{
                    address.formatted_address
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="addressRecord">
            <hr />
            <div class="row">
              <div class="col">
                <input-control
                  v-model="address.house"
                  :cleave="cleave.onlyNumbers"
                  :required="true"
                  autocomplete="false"
                  >House Number</input-control
                >
              </div>
              <div class="col-6">
                <input-control
                  v-model="address.street"
                  :required="true"
                  autocomplete="false"
                  >Street Address</input-control
                >
              </div>
              <div class="col">
                <input-control v-model="address.unit" autocomplete="false"
                  >Unit / Suite</input-control
                >
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input-control
                  v-model="address.city"
                  :required="true"
                  autocomplete="false"
                  >City</input-control
                >
              </div>

              <div class="col">
                <label> Province / State</label>
                <input
                  v-model="address.state"
                  required="true"
                  autocomplete="false"
                  :maxlength="2"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label> Postal Code / Zip</label>
                <input
                  v-model="address.zip"
                  required="true"
                  autocomplete="false"
                  :maxlength="7"
                  :minlength="5"
                  class="form-control"
                />
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Country</label>
                  <select
                    class="form-control"
                    :required="true"
                    v-model="address.country"
                  >
                    <option
                      v-for="(c, key) in countries"
                      :key="key"
                      :value="c.abbreviation"
                    >
                      {{ c.country }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="shippingAddress"
                    v-model="address.shipping"
                  />
                  <label class="custom-control-label" for="shippingAddress"
                    >Residential Address</label
                  >
                </div>
              </div>
              <div class="col">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="billingAddress"
                    v-model="address.billing"
                  />
                  <label class="custom-control-label" for="billingAddress"
                    >Billing Address</label
                  >
                </div>
              </div>
            </div>
            <save
              classes="mt-3 btn btn-theme"
              :saving="providers.address.isSaving"
              >Save Address</save
            >
          </div>
          <button v-if="src" class="btn btn-link" @click.prevent="navigateBack">
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { default as countries } from "country-json/src/country-by-abbreviation.json";
import { debounce } from "lodash";
import axios from "axios";

export default {
  name: "ProviderAddAddress",
  computed: {
    ...mapState(["providers"]),
    hasAddressComponents: function () {
      return this.address.street && this.address.zip && this.address.house
        ? true
        : false;
    },
    countries: function () {
      return countries;
    },
    src: function () {
      return this.$route.query.src;
    },
  },
  data() {
    return {
      geocodeAddress: "",
      addressRecord: false,
      searching: false,
      results: [],
      searchAddress: null,
      userId: null,
      address: {
        house: null,
        street: null,
        unit: null,
        city: null,
        state: null,
        country: null,
        zip: null,
        lat: null,
        lng: null,
        billing: false,
        shipping: true,
      },
      cleave: {
        onlyNumbers: {
          numeral: true,
          numeralDecimalScale: 0,
          delimiter: "",
          stripLeadingZeroes: false,
        },
      },
    };
  },
  created() {
    this.$store
      .dispatch("providers/get", { id: this.$route.params.id })
      .then((res) => {
        this.userId = res?.user_id;
        this.mountGoogleSearch();
      });
  },
  methods: {
    saveAddress: function (e) {
      e.preventDefault();

      this.$store.dispatch("providers/address/create", this.address);
    },
    mountGoogleSearch: function () {
      let vm = this;
      this.geocodeAddress = debounce(async function (e) {
        vm.searching = true;
        let address = e.target.value;
        if (!address) {
          vm.searching = false;
          vm.results = [];
          return;
        }
        let res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${
            process.env.VUE_APP_GOOGLE ||
            "AIzaSyATebGots3HbXasYoIJsf8Ok63eDU_llX0"
          }`
        );

        if (res.status == 200 && res.data.status == "OK") {
          vm.results = res.data.results;
        }
        vm.searching = false;
      }, 500);
    },
    selectAddress: function () {
      if (this.addressRecord.address_components.length > 0) {
        let keyMatch = {
          subpremise: "unit",
          street_number: "house",
          route: "street",
          locality: "city",
          administrative_area_level_1: "state",
          country: "country",
          postal_code: "zip",
        };
        let addressData = [];

        this.addressRecord.address_components.forEach((d) => {
          if (typeof keyMatch[d.types[0]] != "undefined") {
            let key = keyMatch[d.types[0]];
            let value = d.short_name;

            addressData[key] = value;
          }
        });
        let latLng = {
          lat: null,
          lng: null,
        };
        if (this.addressRecord.geometry.location) {
          latLng = {
            lat: parseFloat(this.addressRecord.geometry.location.lat),
            lng: parseFloat(this.addressRecord.geometry.location.lng),
          };
        }

        this.address = {
          provider_id: this.$route.params.id,
          user_id: this.userId,
          ...addressData,
          ...latLng,
          billing: false,
          shipping: true,
        };
      }
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
